<template>
  <!-- 下载专区 -->

  <div class="download-manament">
    <div class="center-container">
      <div class="left-content">
        <div class="s-title s-title-b">
          <span class="decoration"></span>
          <span class="title">下载专区</span>
        </div>
        <div class="change">
          <div
            v-for="(item, index) in types"
            :key="index"
            style="margin-bottom: 13px"
          >
            <div
              :class="{ active: currentIndex == index }"
              class="change-item"
              @click="changeType(item, index)"
            >
              <span>{{ item.name }}</span>
            </div>
            <div
              class="item-children"
              v-if="
                item.children &&
                item.children.length > 0 &&
                currentIndex == index
              "
            >
              <div
                class="change-item2"
                v-for="(item2, index2) in item.children"
                :key="index2"
                :class="{
                  active2: currentIndex2 == index2 && currentIndex == index,
                }"
                @click="changeItemType(item2, index2)"
              >
                <span>{{ item2.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-content">
        <div class="s-title s-title-b">
          <span>当前位置：</span>
          <span>下载专区 > </span>
          <!-- <span>{{ currentType.name }} > {{ currentType2.name }}</span> -->
        </div>
        <div class="info">
          <div class="table-card">
            <el-table
              border
              class="table-head-blue"
              :data="tableData"
              style="width: 100%"
              @row-click="handleRowClick"
            >
              <el-table-column
                align="center"
                prop="title"
                label="标题"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="createTime"
                label="发布时间"
                width="260px"
              ></el-table-column>
            </el-table>
            <div class="pagenation">
              <el-pagination
                background
                layout="total, slot, prev, pager, next, slot"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                @current-change="currentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFileLists } from "../../api/download";
import { getMessageType } from "@/api/add";
import { log } from "console";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      types: [],
      level1Type: [],
      level2Type: [],
      currentIndex: 0,
      currentType: {},
      currentIndex2: 0,
      currentType2: {},
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
    };
  },
  computed: mapGetters(["topSelectAreacode"]),
  created() {
    this.getType();
  },
  watch: {
    topSelectAreacode() {
      this.getType();
    },
  },
  methods: {
    changeType(item, index) {
      // 切换类型
      this.currentIndex = index;
      this.currentType = item;

      this.$router.push({ name: "download", query: { index: index } });
      this.changeItemType(item.children[0], 0);
    },
    changeItemType(item, index) {
      // 切换子项
      this.currentIndex2 = index;
      this.currentType2 = item;
      this.initData();
    },
    async initData() {
      console.log(this.currentType);

      // 加載表格数据
      let params = {
        current: this.currentPage,
        size: this.pageSize,
        // articleFid: this.currentType.type,
        areaCode: this.topSelectAreacode,
        catalog:
          this.currentType.name == "资料文档"
            ? this.currentType.value
            : this.currentType2.value,
      };
      let res = await getFileLists(params);
      let { code, data } = res;
      if (code == 0) {
        console.log(data.records, "records");
        this.tableData = data.records;
        this.total = parseInt(data.total);
        this.currentType = "";
      }
    },
    currentChange(val) {
      // 切换分页
      this.currentPage = val;
      this.initData();
    },
    handleRowClass({ row, rowIndex }) {
      // 行樣式
      if (rowIndex % 2 === 0) {
        return "odd";
      }
    },
    async handleRowClick(row, rowIndex) {
      this.showNewsDetail(row);
    },
    showNewsDetail(article) {
      // 新闻详情
      this.$router.push({ name: "newsPage", query: { c: article.articleId } });
    },
    async getType() {
      let res = await getMessageType({
        level: 1,
      });
      if (res.code == 0) {
        res.data.forEach((item) => {
          item.name = item.remark;
          item.value = item.code;
        });
      }
      this.level1Type = res.data;
      let res2 = await getMessageType({
        level: 2,
      });
      if (res2.code == 0) {
        res2.data.forEach((item) => {
          item.name = item.remark;
          item.value = item.code;
        });
      }
      this.level2Type = res2.data;
      if (this.level1Type && this.level2Type) {
        this.level1Type = this.level1Type.map((x) => {
          let level2 = [];
          this.level2Type.map((y) => {
            if (y.value.slice(0, 4) == x.value) {
              level2.push(y);
            }
          });
          return {
            name: x.name,
            value: x.value,
            children: level2,
          };
        });
        this.types = this.level1Type.filter((x) => {
          return x.value != "0203";
        });
        let index = Number(this.$route.query.index);
        this.changeType(this.types[index], index);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.download-manament {
  // background: #fff;
  padding-top: 20px;
  min-height: 793px;
  padding-bottom: 132px;
  .info {
    .table-card {
      margin: 0;
    }
  }
  .change {
    .change-item {
      margin-top: 13px;
      margin-bottom: 0;
      > span {
        display: block;
        text-align: center;
      }
    }
    .change-item:first-child {
      margin-top: 0;
    }
    .item-children {
      padding: 13px 38px;
      background-color: #fff;
      border-radius: 5px;
      // margin-bottom: 13px;
      .change-item2 {
        height: 40px;
        line-height: 40px;
        background-color: rgba(0, 160, 233, 0.1);
        border-radius: 5px;
        color: #00a0e9;
        margin-top: 12px;
        cursor: pointer;
        > span {
          display: block;
          text-align: center;
        }
      }
      .active2 {
        background-color: #00a0e9;
        color: #fff;
      }
      .change-item2:first-child {
        margin-top: 0;
      }
    }
  }
}
</style>

<style lang="less">
.download-manament {
  .info {
    .el-table__row {
      cursor: pointer;
    }
  }
  .pagenation {
    .el-pagination.is-background .el-pager li:not(.disabled).active {
      border-radius: 0;
    }
  }
}
</style>
